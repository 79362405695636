.mat-form-field-underline,
.mat-form-field-ripple {
    background-color: #949494 !important;
}

.mat-card:not([class*='mat-elevation-z']) {
    box-shadow: none;
    border-radius: 0;
}

.mat-card {
    background: #eeebda;
    color: #221b3b;
}

.mat-tooltip {
    color: #211c39 !important;
    font-family: 'Ninety One Visuelt';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    padding: 12px !important;
    background: #dbd8c0 !important;
    border-radius: 0 !important;
}

.mat-select-panel {
    background: white;
}
