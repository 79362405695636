@import 'colours';

*,
::before,
::after {
    box-sizing: inherit;
}

body {
    font-family: 'Ninety One Visuelt', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.3;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    color: $bodyColour;

    &.default-layout,
    &.white-layout {
        max-width: 1920px;
        margin: auto;
    }

    &.default-layout {
        background-color: $primaryColor;
    }

    &.white-layout {
        background-color: $white;
    }

    app-default-layout,
    app-white-layout {
        padding: 0 8px;
    }

    app-default-layout,
    app-white-layout {
        //width: 100%;
        padding-right: 1.25rem;
        padding-left: 1.25rem;
        margin-right: auto;
        margin-left: auto;
        max-width: 100rem;
    }

    app-landing-page,
    app-product,
    user-selection-page,
    new-customer-landing-page,
    app-default-layout,
    app-white-layout {
        display: block;
    }

    /*320px*/
    @media (min-width: 20rem) {
        app-landing-page,
        app-product,
        user-selection-page,
        new-customer-landing-page,
        app-default-layout,
        app-white-layout {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
    }

    /*375px*/
    @media (min-width: 23.45rem) {
        app-landing-page,
        app-product,
        user-selection-page,
        new-customer-landing-page,
        app-default-layout,
        app-white-layout {
            padding-right: 1rem;
            padding-left: 1rem;
        }
    }

    /*425px*/
    @media (min-width: 26.5rem) {
        app-landing-page,
        app-product,
        user-selection-page,
        new-customer-landing-page,
        app-default-layout,
        app-white-layout {
            padding-right: 1.2rem;
            padding-left: 1.2rem;
        }
    }

    /*768px*/
    @media (min-width: 48rem) {
        app-landing-page,
        app-product,
        user-selection-page,
        new-customer-landing-page,
        app-default-layout,
        app-white-layout {
            padding-right: 2rem;
            padding-left: 2rem;
        }
    }

    /*1024px*/
    @media (min-width: 64rem) {
        app-landing-page,
        app-product,
        user-selection-page,
        new-customer-landing-page,
        app-default-layout,
        app-white-layout {
            padding-right: 3rem;
            padding-left: 3rem;
        }
    }

    /*1440px*/
    @media (min-width: 90rem) {
        app-landing-page,
        app-product,
        user-selection-page,
        new-customer-landing-page,
        app-default-layout,
        app-white-layout {
            padding-right: 3.8rem;
            padding-left: 3.8rem;
        }
    }

    /*1680px*/
    @media (min-width: 105rem) {
        app-landing-page,
        app-product,
        user-selection-page,
        new-customer-landing-page,
        app-default-layout,
        app-white-layout {
            padding-right: 8rem;
            padding-left: 8rem;
        }
    }

    @media only screen and (min-width: 768px) {
    }

    /* --- 1024px - iPad (Most) - Landscape --- */
    @media only screen and (min-width: 1024px) {
        margin: auto;
    }

    /* --- 1440px - Macbook Pro and Common Laptops --- */
    @media only screen and (min-width: 1440px) {
        //max-width: 1920px;
        margin: auto;
    }

    .slider-tooltip {
        max-width: 145px;
        margin-top: 1.5em;
        text-align: center;
        overflow: visible;
        color: #eeebda !important;
        background: #221b3b !important;
        padding: 8px 20px !important;

        &:after {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-bottom-color: #221b3b;
            border-width: 12px;
            margin-left: -12px;
        }
    }
}
