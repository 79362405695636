/*This file should not be imported into other SCSS files. It is loaded globally for the whole app*/
@font-face {
    font-family: 'Ninety One Visuelt';
    src: url('/assets/fonts/NinetyOneVisuelt-Light.eot?#iefix');
    src: url('/assets/fonts/NinetyOneVisuelt-Light.eot?#iefix') format('eot'),
        url('/assets/fonts/NinetyOneVisuelt-Light.woff2') format('woff2'), url('/assets/fonts/NinetyOneVisuelt-Light.woff') format('woff'),
        url('/assets/fonts/NinetyOneVisuelt-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Ninety One Visuelt Display';
    src: url('/assets/fonts/NinetyOneVisueltDisplay-Light.eot?#iefix');
    src: url('/assets/fonts/NinetyOneVisueltDisplay-Light.eot?#iefix') format('eot'),
        url('/assets/fonts/NinetyOneVisueltDisplay-Light.woff2') format('woff2'),
        url('/assets/fonts/NinetyOneVisueltDisplay-Light.woff') format('woff'),
        url('/assets/fonts/NinetyOneVisueltDisplay-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Ninety One Visuelt';
    src: url('/assets/fonts/NinetyOneVisuelt-Regular.eot?#iefix');
    src: url('/assets/fonts/NinetyOneVisuelt-Regular.eot?#iefix') format('eot'),
        url('/assets/fonts/NinetyOneVisuelt-Regular.woff2') format('woff2'),
        url('/assets/fonts/NinetyOneVisuelt-Regular.woff') format('woff'),
        url('/assets/fonts/NinetyOneVisuelt-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Ninety One Visuelt Display';
    src: url('/assets/fonts/NinetyOneVisueltDisplay-Regular.eot?#iefix');
    src: url('/assets/fonts/NinetyOneVisueltDisplay-Regular.eot?#iefix') format('eot'),
        url('/assets/fonts/NinetyOneVisueltDisplay-Regular.woff2') format('woff2'),
        url('/assets/fonts/NinetyOneVisueltDisplay-Regular.woff') format('woff'),
        url('/assets/fonts/NinetyOneVisueltDisplay-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Ninety One Visuelt';
    src: url('/assets/fonts/NinetyOneVisuelt-Medium.eot?#iefix');
    src: url('/assets/fonts/NinetyOneVisuelt-Medium.eot?#iefix') format('eot'),
        url('/assets/fonts/NinetyOneVisuelt-Medium.woff2') format('woff2'), url('/assets/fonts/NinetyOneVisuelt-Medium.woff') format('woff'),
        url('/assets/fonts/NinetyOneVisuelt-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
