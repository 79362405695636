//@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'mixins';
@import 'colours';

@import 'flags.css';
$flag-icon-css-path: '/images/flags' !default;
$flag-icon-rect-path: '' !default;
@import '~flag-icon-css/sass/flag-icon';

@include NinetyOneH1();
@include NinetyOneH2();
@include NinetyOneH3();
@include NinetyOneH4();
@include NinetyOneH5();
@include NinetyOneH6();

.d-flex {
    display: flex;

    /*Justify Content*/
    &.flex-justify-start {
        justify-content: flex-start;
    }
    &.flex-justify-end {
        justify-content: flex-end;
    }
    &.flex-justify-center {
        justify-content: center;
    }
    &.flex-justify-space-between {
        justify-content: space-between;
    }
    &.flex-justify-space-around {
        justify-content: space-around;
    }
    &.flex-justify-space-evenly {
        justify-content: space-evenly;
    }
    &.flex-justify-space-evenly {
        justify-content: space-evenly;
    }

    /*Align Items*/
    &.flex-align-stretch {
        align-items: stretch;
    }
    &.flex-align-flex-start {
        align-items: flex-start;
    }
    &.flex-align-flex-end {
        align-items: flex-end;
    }
    &.flex-align-center {
        align-items: center;
    }
    &.flex-align-baseline {
        align-items: baseline;
    }

    .flex-grow-1 {
        flex-grow: 1;
    }
}
.font-weight-500 {
    font-weight: 500;
}

.text-center {
    text-align: center;
}

.small {
    font-size: 12px;
}
.smallx {
    font-size: 14px;
    font-weight: 400;
}

input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

input {
    overflow: visible;
}

select {
    text-transform: none;
}

a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@import 'buttons';

/*Colours*/

.text-primary {
    color: $ocean-teal;
}
