@import '@angular/material/theming';
@import 'palette';

$white: map_get($md-white, 500);
$black: map_get($md-iam-black-palette, 500);
$bodyColour: #221b3b;

/*Springbok Cream-Ocean Teal - Colour Scheme*/
/*Refer to: https://storybook-bethany.azurewebsites.net/?path=/story/stylekit-colour-schemes--default*/
$primaryColor: map_get($md-springbok-cream, 500);
$primaryTint1: map_get($md-springbok-cream, 600);
$primaryTint2: map_get($md-springbok-cream, 700);
$primaryTint3: map_get($md-springbok-cream, 900);

$secondaryColor: map_get($md-agulhas-indigo, 500);
$secondaryTint1: map_get($md-agulhas-indigo, 600);
$secondaryTint2: map_get($md-agulhas-indigo, 700);
$secondaryTint3: map_get($md-agulhas-indigo, 800);

$accentColor: map_get($md-ocean-teal, 500);
$accentTint1: map_get($md-ocean-teal, 600);
$accentTint2: map_get($md-ocean-teal, 700);
$accentTint3: map_get($md-ocean-teal, 800);

/*Marula Green*/
$primaryColorMarulaGreen: map_get($md-marula-green, 500);

/*Galjoen Grey*/
$primaryColorGaljoenGrey: map_get($md-galjoen-grey, 500);

$springbok-cream-btn-border: $bodyColour;
$springbok-cream-btn-text: $springbok-cream-btn-border;

$springbok-cream: #e8e5ce;
$springbok-cream-secondary: #dbd8c0;
$springbok-cream-btn-border: #221b3b;
$springbok-cream-btn-text: $springbok-cream-btn-border;

$ocean-teal: #0da287;
$ocean-teal-secondary: #026e62;

$white: #ffffff;
