@use '@angular/material' as mat;

@import 'base';
@import './_typography';
@import '../variables/ng-select-theme.scss';

@include mat.core();

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($springbok-cream-theme);

@include mat.icon-theme($springbok-cream-theme-accent);
@include ng-select-theme($springbok-cream-theme);
@include mat.progress-spinner-theme($springbok-cream-theme);

.mat-select-panel {
    background: #eeebda !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #cf6f13;
}
