@import 'colours';

button {
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.btn {
    cursor: pointer;
    display: inline-flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    max-width: none;
    min-width: 0;
    transition: all 250ms ease-in-out 0s;
    border: none;

    &.btn-transparent {
        background-color: transparent;
    }

    &.btn-small {
        font-size: 12px;
        font-weight: 500;
        min-height: 2.2rem;
        padding: 0 1.2rem;

        .a-icon {
            svg {
                width: 1.2rem;
                height: 1.2rem;
            }
        }

        &.wide {
            font-size: 12px;
            font-weight: 500;
            min-height: 2.2rem;
            padding: 0 1.2rem;
            min-width: 150px;
        }
    }

    &.btn-normal {
        font-size: 14px;
        font-weight: 500;
        min-height: 3rem;
        padding: 0.75rem 1.5rem;

        .a-icon {
            svg {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }

    &:not(.plain) {
        text-decoration: none;
    }

    &.btn-small {
        &:focus,
        &:hover,
        &.btn-selected {
            padding-right: 2rem;
            padding-left: 2rem;
            min-width: 100px;
            max-width: 400px;
            transition: all 250ms ease-in-out 0s;
            outline: 0;

            &.wide {
                min-width: 150px;
            }

            > .a-icon {
                transform: translate3d(-1.2rem, 0px, 0px);
                transition: transform 350ms ease-out 0s;
            }

            > .btn-label {
                transform: translate3d(0.85rem, 0px, 0px);
                transition: transform 250ms ease-in 0s;
            }
        }
    }

    &.btn-normal {
        &:focus,
        &:hover,
        &.btn-selected {
            padding-right: 2.5rem;
            padding-left: 2.5rem;
            min-width: 115px;
            max-width: 400px;
            transition: all 250ms ease-in-out 0s;
            outline: 0;

            > .a-icon {
                transform: translate3d(-1.5rem, 0px, 0px);
                transition: transform 350ms ease-out 0s;
            }

            > .btn-label {
                transform: translate3d(0.75rem, 0px, 0px);
                transition: transform 250ms ease-in 0s;
            }
        }
    }

    &.btn-springbok-cream {
        color: $springbok-cream-btn-text;
        background-color: $primaryTint1;

        &.btn-transparent {
            background-color: transparent;
        }

        border: 1px solid $springbok-cream-btn-border;

        &:focus,
        &:hover,
        &.btn-selected {
            color: $primaryColor;

            &::before {
                transform: translate3d(0px, 0px, 0px);
                transition: transform 250ms ease-out 0s;
            }
        }

        &::before {
            background-color: $secondaryColor;
        }
    }

    &.btn-ocean-teal {
        color: $primaryColor;
        background-color: $accentTint1;

        &.btn-transparent {
            background-color: transparent;
        }

        &:focus,
        &:hover,
        &.btn-selected {
            color: $primaryColor;

            &::before {
                transform: translate3d(0px, 0px, 0px);
                transition: transform 250ms ease-out 0s;
            }
        }

        &::before {
            background-color: $accentTint3;
        }
    }

    &.btn-indigo {
        color: $primaryColor;
        background-color: $secondaryColor;

        &:focus,
        &:hover,
        &.btn-selected {
            color: $primaryColor;

            &::before {
                transform: translate3d(0px, 0px, 0px);
                transition: transform 250ms ease-out 0s;
            }
        }

        &::before {
            background-color: $accentTint1;
        }
    }

    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: translate3d(-101%, 0px, 0px);
        transition: transform 250ms ease-in 0s;
    }
}

@media (min-width: 48rem) {
    .btn {
        max-width: 230px;
    }
}

@media (min-width: 48rem) {
    button.btn {
        flex: 0 0 auto;
    }
}

/*Icon*/
.a-icon {
    display: inline-block;
    font-size: 0;
    transform: translate3d(-6rem, 0px, 0px);
    transition: color 150ms ease-in-out 0s, fill 150ms ease-in-out 0s, transform 250ms ease-in-out 0s;
    position: absolute;
    z-index: 1;
    padding-left: 0;
}

/*Empty span*/
.btn span {
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
    position: absolute !important;
}

/*svg*/
.btn {
    &.btn-springbok-cream svg {
        color: $primaryColor;
        fill: $primaryColor;
    }

    &.btn-ocean-teal svg {
        color: $primaryColor;
        fill: $primaryColor;
    }

    &.btn-indigo svg {
        color: $primaryColor;
        fill: $primaryColor;
    }
}

/*Button label*/
.btn {
    .btn-label {
        display: block;
        width: 100%;
        text-align: center;
        white-space: nowrap;
        height: 14px;
        padding-right: 0;
        z-index: 2;
        transform: translate3d(0px, 0px, 0px);
        transition: transform 250ms ease-in 0s;
    }
}

.btn-card-padding {
    padding-right: 16px;
}

.btn:not(.compact) {
    .btn-label {
        min-width: 42px;
    }
}

.risk-buttons-container {
    button {
        padding: 10px 16px;
        border: none;
    }
}
