@import '@angular/material/theming';
@import 'palette';

$mat-light-theme-background: (
    status-bar: map_get($md-springbok-cream, 500),
    app-bar: map_get($md-springbok-cream, 500),
    background: map_get($md-springbok-cream, 500),
    hover: rgba(black, 0.04),
    card: map_get($md-springbok-cream, 600),
    dialog: map_get($md-springbok-cream, 500),
    disabled-button: rgba(black, 0.12),
    raised-button: map_get($md-white, 500),
    focused-button: $dark-focused,
    selected-button: map_get($mat-grey, 300),
    selected-disabled-button: map_get($mat-grey, 400),
    disabled-button-toggle: map_get($mat-grey, 200),
    unselected-chip: map_get($mat-grey, 300),
    disabled-list-option: map_get($mat-grey, 200),
    tooltip: #dbd8c0,
);

// Foreground palette for light themes.
$mat-light-theme-foreground: (
    base: map_get($md-springbok-cream, 400),
    divider: $dark-dividers,
    dividers: $dark-dividers,
    disabled: $dark-disabled-text,
    disabled-button: rgba(black, 0.26),
    disabled-text: $dark-disabled-text,
    elevation: black,
    hint-text: $dark-disabled-text,
    secondary-text: $dark-secondary-text,
    icon: map_get($md-springbok-cream, 500),
    icons: map_get($md-springbok-cream, 500),
    text: #221b3b,
    slider-min: rgba(black, 0.87),
    slider-off: rgba(black, 0.26),
    slider-off-active: rgba(black, 0.38),
    tooltip: #211c39,
);

/* Palettes */
$springbok-cream-primary-palette: mat-palette($palette-springbok-cream, default); // $ClientApp-primary-palette
$springbok-cream-accent-palette: mat-palette($palette-ocean-teal, default); // $ClientApp-accent-palette
$springbok-cream-warn-palette: mat-palette($palette-warn-yellowood, default); // $ClientApp-warn-palette
$springbok-cream-error-palette: mat-palette($palette-protea-red, default); // $ClientApp-error-palette

$agulhas-indigo-primary-palette: mat-palette($palette-agulhas-indigo, default); // $ClientApp-text-palette
$ocean-teal-primary-palette: mat-palette($palette-ocean-teal, default);

$white-palette: mat-palette($palette-white, default); // $ClientApp-white-palette

/* End Palettes */

/* Themes */

$springbok-cream-theme: mat-light-theme($springbok-cream-primary-palette, $springbok-cream-accent-palette, $springbok-cream-error-palette);
$springbok-cream-theme-accent: mat-light-theme(
    $springbok-cream-accent-palette,
    $springbok-cream-accent-palette,
    $springbok-cream-error-palette
);

/* End Themes */

//Primary
$ClientApp-color-primary: mat-color($palette-springbok-cream, default);
$ClientApp-color-primary-secondary: mat-color($palette-springbok-cream, 600);
$ClientApp-color-primary-tertiary: mat-color($palette-springbok-cream, 700);
$ClientApp-color-primary-quaternary: mat-color($palette-springbok-cream, 800);
$ClientApp-color-primary-10-opacity: mat-color($palette-springbok-cream, A100);
$ClientApp-color-primary-20-opacity: mat-color($palette-springbok-cream, A200);

//Accent
$ClientApp-color-accent: mat-color($palette-springbok-cream, default);
$ClientApp-color-accent-secondary: mat-color($palette-ocean-teal, 600);
$ClientApp-color-accent-tertiary: mat-color($palette-ocean-teal, 700);
$ClientApp-color-accent-quaternary: mat-color($palette-ocean-teal, 800);
$ClientApp-color-accent-10-opacity: mat-color($palette-ocean-teal, A100);
$ClientApp-color-accent-20-opacity: mat-color($palette-ocean-teal, A200);

//Warn
$ClientApp-color-warn: mat-color($palette-warn-yellowood, default);
$ClientApp-color-warn-secondary: mat-color($palette-warn-yellowood, 600);
$ClientApp-color-warn-tertiary: mat-color($palette-warn-yellowood, 700);
$ClientApp-color-warn-quaternary: mat-color($palette-warn-yellowood, 800);
$ClientApp-color-warn-10-opacity: mat-color($palette-warn-yellowood, A100);
$ClientApp-color-warn-20-opacity: mat-color($palette-warn-yellowood, A200);

//Error
$ClientApp-color-error: mat-color($palette-protea-red, default);
$ClientApp-color-error-secondary: mat-color($palette-protea-red, 600);
$ClientApp-color-error-tertiary: mat-color($palette-protea-red, 700);
$ClientApp-color-error-quaternary: mat-color($palette-protea-red, 800);
$ClientApp-color-error-10-opacity: mat-color($palette-protea-red, A100);
$ClientApp-color-error-20-opacity: mat-color($palette-protea-red, A200);

//Text
$ClientApp-color-text-secondary: mat-color($palette-agulhas-indigo, 600);
$ClientApp-color-text-tertiary: mat-color($palette-agulhas-indigo, 700);
$ClientApp-color-text-quaternary: mat-color($palette-agulhas-indigo, 800);
$ClientApp-color-text-10-opacity: mat-color($palette-agulhas-indigo, A100);
$ClientApp-color-text-20-opacity: mat-color($palette-agulhas-indigo, A200);

//White
$ClientApp-color-white: mat-color($palette-white, default);
$ClientApp-color-white-secondary: mat-color($palette-white, 600);
$ClientApp-color-white-tertiary: mat-color($palette-white, 700);
$ClientApp-color-white-quaternary: mat-color($palette-white, 800);
$ClientApp-color-white-10-opacity: mat-color($palette-white, A100);
$ClientApp-color-white-20-opacity: mat-color($palette-white, A200);

$ClientApp-original-background: map-get($springbok-cream-theme, background);
$ClientApp-original-foreground: map-get($springbok-cream-theme, foreground);

//Background
$ClientApp-color-background: mat-color(map-get($springbok-cream-theme, background), background);
$ClientApp-color-background-lighter: $ClientApp-color-primary-secondary;
$ClientApp-color-background-darker: $ClientApp-color-primary-tertiary;

$ClientApp-color-primary-contrast: mat-color(map-get($springbok-cream-theme, primary), default-contrast);
$ClientApp-color-primary-lighter: mat-color(map-get($springbok-cream-theme, primary), lighter);
$ClientApp-color-primary-darker: mat-color(map-get($springbok-cream-theme, primary), darker);
$ClientApp-color-accent-contrast: mat-color(map-get($springbok-cream-theme, accent), default-contrast);
$ClientApp-color-accent-lighter: mat-color(map-get($springbok-cream-theme, accent), lighter);
$ClientApp-color-accent-darker: mat-color(map-get($springbok-cream-theme, accent), darker);
$ClientApp-color-warn-contrast: mat-color(map-get($springbok-cream-theme, warn), default-contrast);
$ClientApp-color-warn-lighter: mat-color(map-get($springbok-cream-theme, warn), lighter);
$ClientApp-color-warn-darker: mat-color(map-get($springbok-cream-theme, warn), darker);
$ClientApp-color-text: map-get(map-get($springbok-cream-theme, foreground), text);
$ClientApp-color-card: mat-color(map-get($springbok-cream-theme, background), card);
