@mixin NinetyOneH1() {
    h1 {
        font-family: 'Ninety One Visuelt Display', sans-serif;
        font-weight: 200;
        line-height: 1.2;
        letter-spacing: -1.2px;
    }

    /* --- 768px - iPad (All) - Portrait --- */
    @media only screen and (min-width: 768px) {
        h1 {
            font-size: 60px;
            font-size: 3.75rem;
        }
    }

    /* --- 1024px - iPad (Most) - Landscape --- */
    @media only screen and (min-width: 1024px) {
        h1 {
            font-size: 75px;
            font-size: 4.6875rem;
        }
    }

    /* --- 1440px - Macbook Pro and Common Laptops --- */
    @media only screen and (min-width: 1440px) {
        h1 {
            font-size: 72px;
            font-size: 4.5rem;
        }
    }
}

@mixin NinetyOneH2() {
    h2 {
        font-family: 'Ninety One Visuelt Display', sans-serif;
        font-weight: 200;
        line-height: 1.2;
        letter-spacing: -1px;
    }

    /* --- 768px - iPad (All) - Portrait --- */
    @media only screen and (min-width: 768px) {
        h2 {
            font-size: 48px;
            font-size: 3rem;
        }
    }

    /* --- 1024px - iPad (Most) - Landscape --- */
    @media only screen and (min-width: 1024px) {
        h2 {
            font-size: 50px;
            font-size: 3.125rem;
        }
    }

    /* --- 1440px - Macbook Pro and Common Laptops --- */
    @media only screen and (min-width: 1440px) {
        h2 {
            font-size: 60px;
            font-size: 3.75rem;
        }
    }
}

@mixin NinetyOneH3() {
    h3 {
        font-family: 'Ninety One Visuelt Display', sans-serif;
        font-weight: 200;
        line-height: 1.2;
        letter-spacing: -0.8px;
    }

    /* --- 768px - iPad (All) - Portrait --- */
    @media only screen and (min-width: 768px) {
        h3 {
            font-size: 36px;
            font-size: 2.25rem;
        }
    }

    /* --- 1024px - iPad (Most) - Landscape --- */
    @media only screen and (min-width: 1024px) {
        h3 {
            font-size: 36px;
            font-size: 2.25rem;
        }
    }

    /* --- 1440px - Macbook Pro and Common Laptops --- */
    @media only screen and (min-width: 1440px) {
        h3 {
            font-size: 50px;
            font-size: 3.125rem;
        }
    }
}

@mixin NinetyOneH4() {
    h4 {
        font-family: 'Ninety One Visuelt Display', sans-serif;
        font-weight: 200;
        line-height: 1.2;
        letter-spacing: -0.6px;
        font-size: 30px;
        font-size: 1.875rem;
    }

    /* --- 768px - iPad (All) - Portrait --- */
    @media only screen and (min-width: 768px) {
        h4 {
            font-size: 32px;
            font-size: 2rem;
        }
    }

    /* --- 1024px - iPad (Most) - Landscape --- */
    @media only screen and (min-width: 1024px) {
        h4 {
            font-size: 30px;
            font-size: 1.875rem;
        }
    }

    /* --- 1440px - Macbook Pro and Common Laptops --- */
    @media only screen and (min-width: 1440px) {
        h4 {
            font-size: 40px;
            font-size: 2.5rem;
        }
    }
}

@mixin NinetyOneH5() {
    h5 {
        font-family: 'Ninety One Visuelt Display', sans-serif;
        font-weight: 300;
        line-height: 1.2;
        letter-spacing: -0.4px;
    }

    /* --- 768px - iPad (All) - Portrait --- */
    @media only screen and (min-width: 768px) {
        h5 {
            font-size: 28px;
            font-size: 1.75rem;
        }
    }

    /* --- 1024px - iPad (Most) - Landscape --- */
    @media only screen and (min-width: 1024px) {
        h5 {
            font-size: 28px;
            font-size: 1.75rem;
        }
    }

    /* --- 1440px - Macbook Pro and Common Laptops --- */
    @media only screen and (min-width: 1440px) {
        h5 {
            font-size: 32px;
            font-size: 2rem;
        }
    }
}

@mixin NinetyOneH6() {
    h6 {
        font-family: 'Ninety One Visuelt', sans-serif;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0.15px;
    }

    /* --- 768px - iPad (All) - Portrait --- */
    @media only screen and (min-width: 768px) {
        h6 {
            font-size: 14px;
            font-size: 0.875rem;
        }
    }

    /* --- 1024px - iPad (Most) - Landscape --- */
    @media only screen and (min-width: 1024px) {
        h6 {
            font-size: 14px;
            font-size: 0.875rem;
        }
    }

    /* --- 1440px - Macbook Pro and Common Laptops --- */
    @media only screen and (min-width: 1440px) {
        h6 {
            font-size: 15px;
            font-size: 0.9375rem;
        }
    }
}
